<template>
    <v-container
        fluid
        fill-height
        class="bs-container"
    >
        <v-row
            :class="['bs-overlay', className,{'bs-overlay--banner-visible':banner}]"
            no-gutters
        >
            <v-col
                cols="12"
                :md="fullWidth?false:(extendedWidth?8:6)"
                :offset-md="fullWidth?undefined:(extendedWidth?2:3)"
                v-bind="isDIR"
                tag="article"
            >
                <slot />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapState, mapWritableState,} from 'pinia'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {useOverlayRouteAction,} from '@/assets/js/src/util/composables/useOverlayRouteAction'

export default {
    name: 'Overlay',
    props: {
        className: {
            type: String,
            default: 'default',
        },
        dir: {
            type: String,
            default: '',
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        extendedWidth: {
            type: Boolean,
            default: false,
        },
        readyToScroll: {
            type: Boolean,
            default: false,
        },
    },
    setup () {
        return {
            ...useOverlayRouteAction(),
        }
    },
    computed: {
        ...mapState(useAppUiStore, [
            'banner',
            'overlay',
            'overlayModal',
            'menu',
            'countRouteChange',
        ]),
        ...mapWritableState(useAppUiStore, [
            'savedYPositionOverlay',
        ]),
        isDIR: function () {
            return this.dir.length === 0 ? null : {
                'dir': this.dir,
            }
        },
    },
    watch: {
        readyToScroll: {
            handler (newValue, oldValue) {
                if (newValue !== oldValue && newValue) {
                    let yPos = this.savedYPositionOverlay
                    if (yPos) {
                        this.savedYPositionOverlay = 0
                        setTimeout(() => {
                            window.scrollTo(0, yPos)
                        })
                    }
                }
            },
            immediate: !import.meta.env.SSR,
        },
    },
    mounted () {
        document.addEventListener('keydown', this.handleKeyUp)

        // Scrolle grundsätzlich nach oben
        setTimeout(() => {
            let presentHash = this.countRouteChange <= 1 && this.$route.hash
            !presentHash && window.scrollTo(0, 0)
        })
    },
    beforeUnmount () {
        document.removeEventListener('keydown', this.handleKeyUp)
    },
    methods: {
        handleKeyUp (event) {
            if (!this._inactive && this.overlay && !this.menu) {
                // Wurde Escape gedrückt
                event = event || window.event
                let isEscape = false
                if ('key' in event) {
                    isEscape = event.key == 'Escape' || event.key == 'Esc'
                } else {
                    isEscape = event.keyCode == 27
                }
                let formElemActive = document.querySelector(
                    '.menuable__content__active'
                )

                // Wenn Escape, dann die closeIcon aufrufen
                if (isEscape && formElemActive === null) {
                    if (!this.overlayModal) {
                        this.overlayRouteAction()
                    }
                }
            }
        },
    },
}
</script>

<style lang="scss">
.bs-app .bs-container {
    padding: 0;
}

.bs-overlay {
    z-index: 9989;
    width: 100vw;
    min-height: 100vh;
    padding-top: map-deep-get($bs-xl, overlay, paddingY) + map-deep-get($bs-xl, header, height);
    padding-bottom: #{map-deep-get($bs-xl, overlay, paddingY)}px;
    background-color: map-deep-get($bs-color, greyLight);

    @include dark {
        background-color: map-deep-get($bs-color, dark, greyLight);
    }

    @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
        padding-top: map-deep-get($bs-md, overlay, paddingY) + map-deep-get($bs-md, header, height);
        padding-bottom: #{map-deep-get($bs-md, overlay, paddingY)}px;
    }

    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
        padding: #{map-deep-get($bs-xs, overlay, paddingY) + map-deep-get($bs-xs, header, height)} #{map-deep-get($bs-xs, overlay, paddingX)}px #{map-deep-get($bs-xs, overlay, paddingY)}px #{map-deep-get($bs-xs, overlay, paddingX)}px;
    }

    &--banner-visible {
        padding-top: map-deep-get($bs-xl, overlay, paddingY) + map-deep-get($bs-xl, header, height)  + map-deep-get($bs-xl, banner, height);

        @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
            padding-top: map-deep-get($bs-md, overlay, paddingY) + map-deep-get($bs-md, header, height)  + map-deep-get($bs-xl, banner, height);
        }

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            padding-top: map-deep-get($bs-xs, overlay, paddingY) + map-deep-get($bs-xs, header, height)  + map-deep-get($bs-xs, banner, height);
        }
    }

    p, span, li {
        color: map-deep-get($bs-color, black);
        font-weight: map-deep-get($bs-xl, overlay, p, font, weight);
        font-size: #{map-deep-get($bs-xl, overlay, p, font, size)}px;

        @include dark {
            color: map-deep-get($bs-color, dark, black);
        }

        @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
            font-weight: map-deep-get($bs-md, overlay, p, font, weight);
            font-size: #{map-deep-get($bs-md, overlay, p, font, size)}px;
        }

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            font-weight: map-deep-get($bs-xs, overlay, p, font, weight);
            font-size: #{map-deep-get($bs-xs, overlay, p, font, size)}px;
        }
    }

    a {
        font-weight: map-deep-get($bs-xl, overlay, p, font, weight);
        font-size: #{map-deep-get($bs-xl, overlay, p, font, size)}px;

        @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
            font-weight: map-deep-get($bs-md, overlay, p, font, weight);
            font-size: #{map-deep-get($bs-md, overlay, p, font, size)}px;
        }

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            font-weight: map-deep-get($bs-xs, overlay, p, font, weight);
            font-size: #{map-deep-get($bs-xs, overlay, p, font, size)}px;
        }
    }

    small {
        color: map-deep-get($bs-color, black);
        font-size: 12px;

        @include dark {
            color: map-deep-get($bs-color, dark, black);
        }
    }

    h1 {
        position: relative;
        top: .1em;
        color: map-deep-get($bs-color, black);
        font-weight: map-deep-get($bs-xl, overlay, h1, font, weight);
        font-size: #{map-deep-get($bs-xl, overlay, h1, font, size)}px;
        line-height: 1.2em;
        hyphens: auto;

        @include dark {
            color: map-deep-get($bs-color, dark, black);
        }

        span {
            color: map-deep-get($bs-color, black);
            font-weight: map-deep-get($bs-xl, overlay, h1, font, weight);
            font-size: #{map-deep-get($bs-xl, overlay, h1, font, size)}px;

            @include dark {
                color: map-deep-get($bs-color, dark, black);
            }
        }

        @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
            font-size: #{map-deep-get($bs-md, overlay, h1, font, size)}px;

            span {
                font-size: #{map-deep-get($bs-md, overlay, h1, font, size)}px;
            }
        }

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            font-size: #{map-deep-get($bs-xs, overlay, h1, font, size)}px;
            line-height: 1.2em;

            span {
                font-size: #{map-deep-get($bs-xs, overlay, h1, font, size)}px;
                line-height: 1.2em;
            }
        }
    }

    h3 {
        margin-top: #{2 * map-deep-get($bs-xl, overlay, p, font, size)}px;
        margin-bottom: #{map-deep-get($bs-xl, overlay, p, font, size)}px;
        color: map-deep-get($bs-color, black);
        font-weight: 700;
        font-size: #{map-deep-get($bs-xl, overlay, p, font, size)}px;

        @include dark {
            color: map-deep-get($bs-color, dark, black);
        }
    }

    h2 + h1 {
        padding-top: 0;
    }

    .v-skeleton-loader {
        &__overlay-heading {
            width: 60%;
            height: 72px;
            margin-bottom: 24px !important;
            background-color: rgb(0 0 0 / 12%);

            @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                height: 54px;
            }

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                height: 38px;
            }
        }
    }
}

@media print {
    #app.bs-app {
        .bs-overlay {
            display: block;
            padding-top: 32px !important;
            padding-right: 0;
            padding-left: 0;
            background-color: #fff !important;

            article {
                flex-basis: 100% !important;
                max-width: 100% !important;
                margin-left: 0% !important;
            }

            p, span, li, a {
                font-size: 10pt;
            }

            h2 {
                font-size: 12pt;
            }
        }

        &.overlay-modal {
            background-color: #fff !important;
        }
    }
}
</style>
